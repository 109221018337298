<template>
	<div>
		<van-row class="product-item" @click="showOrderProgress">
			<van-col span="16" class="product-item-left">
				<div>{{product.productId}}：{{product.realBank}} - {{product.productName}}</div>
				<van-tag class="product-item-status" :color="tagColor[product.statusId]">{{product.status}}</van-tag>
			</van-col>
			<van-col span="8">
				<div class="order-progress"><span>查看进度</span></div>
			</van-col>
		</van-row>
		<order-progress-popup v-model="progressShow" :product="product" :title="progressPopupTitle"></order-progress-popup>
	</div>
</template>

<script>
	import {Col, Row, Tag} from 'vant'
	import OrderProgressPopup from "./OrderProgressPopup";

	export default {
		name: "ProductItem",
		components: {
			OrderProgressPopup,
			[Col.name]: Col,
			[Row.name]: Row,
			[Tag.name]: Tag
		},
		props: {
			product: Object,
			enterpriseName: String
		},
		data() {
			return {
				progressShow: false,
				tagColor: {
					13: '#faad8e',
					14: '#faad8e',
					20: '#57d492',
					91: '',
					92: '',
					93: '',
					100: '#b791dd'
				}
			}
		},
		computed: {
			progressPopupTitle() {
				return this.enterpriseName + ' - ' + this.product.productId
			}
		},
		methods: {
			showOrderProgress() {
				this.progressShow = true
			}
		}
	}
</script>

<style scoped>
	.product-item {
		display: flex;
		margin: 15px 0;
	}

	.product-item-left {
		display: flex;
	}

	.product-item-status {
		margin-left: 15px;
	}

	.order-progress {
		color: #aa85ee;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
</style>