import request from '../util/request'

export default {
  applyLink(goodsId, taxpayerId, matchedUuid) {
    return request({
      url: '/order/applyLink',
      method: 'POST',
      data: {
        goodsId: goodsId,
        taxpayerId: taxpayerId,
        matchedUuid: matchedUuid
      }
    })
  },
  orderOfOrg(data) {
    return request({
      url: '/order/orderOforg',
      method: 'post',
      data
    })
  },
  orderProgress(id) {
    return request({
      url: '/order/progress/' + id,
      method: 'get'
    })
  }
}