<template>
  <van-popup v-model="show" position="bottom" round closeable>
    <div class="title">{{ title }}</div>
    <div class="main">
      <van-row class="amount-detail">
        <van-col span="12" v-if="product.approvedAmount"
          >获批额度：{{ product.approvedAmount }}万元</van-col
        >
        <van-col span="12" v-if="product.loanAmount"
          >放款金额：{{ product.loanAmount }}万元</van-col
        >
      </van-row>
      <van-steps direction="vertical" :active="0" active-color="#7c36ef">
        <van-step class="step-item" v-for="item in progress" :key="item.id">
          <h3 class="step-item-title">{{ item.log }}</h3>
          <p class="step-item-time">{{ item.createdTime }}</p>
        </van-step>
      </van-steps>
    </div>
  </van-popup>
</template>

<script>
import { Popup, Step, Steps, Row, Col } from "vant";
import OrderApi from "../../../../api/order";
export default {
  name: "OrderProgressPopup",
  components: {
    [Popup.name]: Popup,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Col.name]: Col,
    [Row.name]: Row,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      show: this.value,
      progress: [],
    };
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.progress = [];
      OrderApi.orderProgress(this.product.id).then((res) => {
        this.progress = res.data;
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 36px;
  margin: 30px;
}
.main {
  margin: 0 30px;
  height: 460px;
  overflow-y: auto;
  font-size: 24px;
}
.step-item {
  margin-bottom: 15px;
  font-size: 24px;
}
.step-item-title,
.step-item-time {
  margin-left: 20px;
}
.step-item-title {
  line-height: 35px;
}
.step-item-time {
  margin-top: 20px;
  line-height: 35px;
}
.amount-detail {
  margin-bottom: 30px;
}
</style>