<template>
  <div>
    <jzsy-header :title="title">
      <div slot="right" class="filter-btn">
        <div
          class="clean-date-btn"
          v-show="params.start || params.end"
          @click="cleanDate"
        >
          清除
        </div>
        <md-icon
          class="search-btn"
          name="search"
          size="lg"
          @click="showSearch"
          v-show="!searchShow"
        ></md-icon>
        <md-icon name="calendar" size="lg" @click="showCalendar"></md-icon>
      </div>
    </jzsy-header>
    <van-sticky offset-top="46px">
      <md-input-item
        class="search-input"
        v-show="searchShow"
        v-model="params.enterpriseName"
        type="text"
        placeholder="企业名称、信用代码"
        is-highlight
        clearable
        @change="keywordsSearch"
      >
        <md-icon name="search" slot="left" color="#9579E5"></md-icon>
        <label slot="right" @click="hideSearch">关闭</label>
      </md-input-item>
    </van-sticky>
    <van-calendar
      v-model="calendarShow"
      type="range"
      :min-date="new Date(2020, 0, 1)"
      :max-date="new Date()"
      :default-date="defaultDate"
      title="选择起止日期"
      color="#7147B5"
      :max-range="366"
      range-prompt="日期跨度不能超过一年"
      @confirm="selectDateHandle"
    />
    <template v-if="list && list.length > 0">
      <van-list
        v-model="list.loading"
        :finished="list.finished"
        finished-text="没有更多了"
        @load="nextPage"
      >
        <order-item
          v-for="item in datas"
          :key="item.id"
          :order="item"
        ></order-item>
      </van-list>
    </template>
    <template v-else>
      <van-empty description="暂无记录" />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { List, Sticky, Empty } from "vant";
import OrderItem from "./components/OrderItem";
import OrderApi from "../../../api/order";

export default {
  name: "List",
  components: {
    OrderItem,
    [List.name]: List,
    [Sticky.name]: Sticky,
    [Empty.name]: Empty,
    [OrderItem.name]: OrderItem,
  },
  data() {
    return {
      params: {
        start: null,
        end: null,
        enterpriseName: "",
      },
      datas: [],
      isFinished: false,
      searchShow: false,
      calendarShow: false,
      searchKeywords: "",
      searchTimer: null,
      list: {
        loading: false,
        finished: false,
      },
    };
  },
  computed: {
    ...mapState("login", ["isAdmin"]),
    defaultDate() {
      let now = new Date();
      return [new Date(now.getFullYear(), now.getMonth(), 1), new Date()];
    },
    title() {
      let str = "申请记录";
      if (this.params.start && this.params.end) {
        str = `${this.params.start.getFullYear()}/${
          this.params.start.getMonth() + 1
        }/${this.params.start.getDate()}~${this.params.end.getFullYear()}/${
          this.params.end.getMonth() + 1
        }/${this.params.end.getDate()}`;
      } else if (this.params.start) {
        str = `${this.params.start.getFullYear()}/${
          this.params.start.getMonth() + 1
        }/${this.params.start.getDate()}~今天`;
      } else if (this.params.end) {
        str = `${this.params.end.getFullYear()}/${
          this.params.end.getMonth() + 1
        }/${this.params.end.getDate()}之前`;
      }
      return str;
    },
  },
  methods: {
    loadData(refresh) {
      this.list.loading = true;
      const params = {
        enterpriseName: this.params.enterpriseName,
        start: this.params.start ? this.params.start.getTime() : null,
        end: this.params.end ? this.params.end.getTime() : null,
      };
      if (this.datas && this.datas.length && !refresh) {
        params.lastId = this.datas[this.datas.length - 1].id;
      }
      OrderApi.orderOfOrg(params)
        .then((res) => {
          if (res.data == null || res.data.length < 10) {
            this.list.finished = true;
          }
          if (refresh) {
            this.datas = res.data;
          } else {
            this.datas = this.datas.concat(res.data);
          }
        })
        .catch(() => {
          this.list.finished = true;
        })
        .finally(() => {
          this.list.loading = false;
        });
    },
    showSearch() {
      this.searchShow = true;
    },
    hideSearch() {
      this.searchShow = false;
      if (this.params.enterpriseName) {
        this.params.enterpriseName = "";
        this.loadData(true);
      }
    },
    showCalendar() {
      this.calendarShow = true;
    },
    cleanDate() {
      this.params.start = null;
      this.params.end = null;
      this.loadData(true);
    },
    selectDateHandle(value) {
      this.params.start = value[0];
      this.params.end = value[1];
      this.loadData(true);
      this.calendarShow = false;
    },
    nextPage() {
      this.loadData(false);
    },
    keywordsSearch() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.loadData(true);
      }, 500);
    },
  },
};
</script>

<style scoped>
.filter-btn {
  margin-right: 40px;
  display: flex;
  align-items: center;
  height: 92px;
}

.clean-date-btn {
  margin-right: 30px;
}

.search-btn {
  margin-right: 30px;
}

.search-input {
  padding: 0 20px;
  background-color: #ffffff;
}
</style>