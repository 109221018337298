<template>
  <div class="wrapper">
    <div class="main-wrapper">
      <div class="content">
        <div class="enterprise-name">{{ order.enterpriseName }}</div>
        <div class="description">
          <div class="accepted-time">受理时间：{{ order.acceptedTime }}</div>
          <van-tag color="#b791dd" plain>{{ order.status }}</van-tag>
        </div>
      </div>
      <!-- <div class="arrow">
        <van-icon name="arrow-down"></van-icon>
      </div> -->
    </div>
    <div class="detail-wrapper">
      <div v-if="order.products && order.products.length">
        <product-item
          v-for="item in order.products"
          :key="item.id"
          :enterprise-name="order.enterpriseName"
          :product="item"
        ></product-item>
      </div>
      <div v-else style="text-align: center; color: #b6b6b6">暂未申请产品</div>
    </div>
  </div>
</template>

<script>
import { Icon, Col, Row, Tag, Button } from "vant";
import ProductItem from "./ProductItem";
export default {
  name: "OrderItem",
  components: {
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tag.name]: Tag,
    [Button.name]: Button,
    ProductItem,
  },
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      detail: {
        show: false,
      },
      orderProgress: {
        show: false,
      },
    };
  },
  methods: {
    showOrderProgress() {
      this.orderProgress.show = true;
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 15px;
  line-height: 36px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 24px;
}
.main-wrapper {
  display: flex;
  flex-direction: row;
}
.arrow {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  flex-grow: 1;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.enterprise-name {
  font-size: 28px;
}
.description {
  display: flex;
  justify-content: space-between;
}
.accepted-time {
  color: #8b8b8b;
}
.detail-wrapper {
  margin-top: 20px;
  padding: 20px 0 10px 0;
  border-top: 1px dashed #ececec;
}
</style>