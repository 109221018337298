import { render, staticRenderFns } from "./OrderProgressPopup.vue?vue&type=template&id=24096a0e&scoped=true&"
import script from "./OrderProgressPopup.vue?vue&type=script&lang=js&"
export * from "./OrderProgressPopup.vue?vue&type=script&lang=js&"
import style0 from "./OrderProgressPopup.vue?vue&type=style&index=0&id=24096a0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24096a0e",
  null
  
)

export default component.exports